@media (max-width:1400px) {
    .intro-section { padding: 50px 20px; }
    .banner-single { min-height: 480px; }

}
@media (max-width:1360px) {
    body { font-size: 20px; line-height: 28px; }
    .container { width: 1140px; }
    .call { margin-bottom: -5px; margin-top: 5px; }
    .call a,.footer-info a.call,.banner-title { font-size: 30px; line-height: 30px; }
    .mail a,.footer-info a.mail { font-size: 22px; line-height: 22px; }
    .slide-content h2,.form-title,.service-data h2,.gallery-text h2 { font-size: 28px; line-height: 35px; }
    .intro-section ul { margin: 35px 0 0; }
    .intro-section ul li { font-size: 18px; line-height: 24px; }
    .one-half.first { padding-right: 30px }
    .one-half { padding-left: 30px; }
    .site-footer { padding: 50px 0; }
    .form-title { margin: 10px 0 5px; }
    .contact-form p { font-size: 18px; line-height: 22px; }
    .contact-form div.wpcf7 { padding: 10px 0; }
    .form-control { height: 45px; line-height: 45px; }
    .submit-btn { margin-top: 20px; }
    .author, .testimonial { font-size: 20px; line-height: 24px; }
    a.btn-testimonial { font-size: 22px; line-height: 24px; margin-top: 0; padding: 8px 30px; }
    .footer-info { bottom: 10px; }
    .copyright { font-size: 18px; line-height: 22px; margin-top: 20px; }

    .banner-content { font-size: 20px; line-height: 30px; max-width: 580px; }
    .banner-title { margin-bottom: 20px; }
    .welcome-section { padding: 50px 0 35px; }
    .vertical-align { padding: 55px 0 70px; }

    .main-serviceimage { padding-left: 25px; }
    .service-block-section .service-block:nth-child(even) .main-serviceimage { padding-right: 25px; }
    .service-data { font-size: 20px; line-height: 26px; }

    .gallery-text h2 { margin-bottom: 20px; }
    .gallery-text { padding: 45px 0; }

    .testimonial:before { top: 23px; left: 15px; width: 100px; height: 70px; }



}
@media (max-width:1200px) {
    .container { width: 960px; }
    .navigation ul li { padding: 5px 9px; }
    .footer-info { padding: 0; bottom: 35px; }
    a.btn-testimonial { font-size: 18px; line-height: 22px; padding: 8px 20px; margin-top: 10px; }
    .contact-form p a { font-size: 20px; }
    .copyright { font-size: 16px; line-height: 20px; padding-top: 15px; }
    .site-footer { padding: 50px 0 70px; }
    div.wpcf7-response-output { margin: 10px 0 0 0; padding: 0; }
    span.wpcf7-not-valid-tip { font-size: 16px; line-height: 18px; }
    div.wpcf7-validation-errors { border: 0px none !important; text-align: center; font-size: 16px; color: #f00; line-height: 20px; }

    .banner-single { min-height: 400px; }
    .vertical-align { padding: 40px 0 50px; }
    .service-data { font-size: 18px; line-height: 24px; }

    .gallery-text { padding: 35px 0; }
    .gallery-text h2 { margin-bottom: 10px; }
    .gallery-image.quarter { height: 350px; }
    .gallery-image.quarter-half { height: 550px; }
    .gallery-image.height-full { height: 900px; }
    .quarter-half-width { height: 550px; }
    .half-height { height: 278px; }
    .half-height-newiamge { height: 272px; }

    .pagecontent-section { padding: 50px 0; }

    .site-header.navsticky .navigation ul li a { padding: 7px 0 0 0; }

    .navigation ul li:hover > ul.sub-menu,.navigation ul li.open > ul.sub-menu { display: block; z-index: 1; position: absolute; top: 0px; left: -20px; width: 180px; text-align: left; margin:40px 0 0 0; background: #FFF; border-top: 3px solid #ffe700; padding: 0 0 10px 0; }
	ul.sub-menu li { padding: 5px 0 0px 15px; line-height: 18px; }
	.navigation ul li:hover > ul.sub-menu li:hover > ul.sub-menu,.navigation ul li.open > ul.sub-menu li.open > ul.sub-menu { left:100%; margin-top: -3px; }
	.site-header.navsticky .navigation ul li:hover > ul.sub-menu li a,.site-header.navsticky .navigation ul li.open > ul.sub-menu li a { padding: 0; }
}



@media (max-width:991px) {
    .container { width: 740px; }
    body { font-size: 18px; line-height: 26px; }
    .site-header { padding: 20px 0 15px; }
    .header-text { padding-top: 0; }
    .call a, .footer-info a.call,.banner-title { font-size: 22px; line-height: 24px; display: inherit; }
    .mail a, .footer-info a.mail { font-size: 16px; line-height: 16px; }
    .navigation ul { margin: 0; }
    .navigation ul li { padding: 5px; }
    .navigation ul li a { padding: 5px 0px 0; font-size: 16px; line-height: 20px; }
    .mail { margin-bottom: 0; }
    .slide-content h2, .form-title,.service-data h2,.gallery-text h2 { font-size: 22px; line-height: 26px; }
    .intro-section { padding: 30px 20px; }
    .intro-section ul { margin: 20px 0 0; }
    .one-half.first { padding-right: 15px; }
    .one-half { padding-left: 15px; }
    .intro-section ul {  padding: 0 0 0 15px;}
    .intro-section ul li { font-size: 16px; line-height: 22px; }
    .site-footer .col-sm-6.pull-right { padding-left: 15px; }
    a.btn-testimonial { font-size: 16px; line-height: 20px; padding: 8px 15px; }
    .testimonial { padding-top: 25px; }
    .author, .testimonial { font-size: 16px; line-height: 20px; }
    .contact-form p { font-size: 14px; line-height: 20px; }
    .contact-form p a { font-size: 15px; }
    .form-control { height: 34px; line-height: 16px; padding: 0 12px; }
    input::-moz-placeholder,textarea::-moz-placeholder { font-size: 16px; line-height: 18px; }
    input::-webkit-input-placeholder,textarea::-webkit-input-placeholder { font-size: 16px; }
    input::-ms-input-placeholder,textarea::-ms-input-placeholder { font-size: 16px; }
    button, html input[type="button"], input[type="reset"], input[type="submit"] { padding: 8px 30px; height: auto; font-size: 20px; }
    .site-footer { padding: 20px 0 90px; }
    .copyright { font-size: 14px; line-height: 18px; }
    .footer-info:before { top: -30px; }

    .banner-single { min-height: 330px; }
    .banner-title { margin-bottom: 10px; }
    .banner-content { font-size: 18px; line-height: 24px; }
    .welcome-section { padding: 30px 0 20px; }
    .service-intro { padding: 0 50px; }
    .service-data h2 { margin-bottom: 0; }
    .service-data { font-size: 16px; line-height: 22px; }
    .main-serviceimage { padding-left: 15px; }
    .service-block-section .service-block:nth-child(even) .main-serviceimage { padding-right: 15px; }
    .services { padding: 0 50px; }

    .gallery-text { padding: 30px 0; }

    .gallery-image.quarter { height: 240px; }
    .gallery-image.quarter-half { height: 450px; }
    .gallery-image.height-full { height: 690px; }
    .quarter-half-width { height: 450px; }
    .half-height { height: 210px; }
    .half-height-newiamge { height: 240px; }

    .testimonial:before { top: 0; left: 0;width: 70px; height: 50px; }
    .site-header.navsticky .navigation ul li { padding: 5px 15px; }

    .navigation ul li:hover > ul.sub-menu,.navigation ul li.open > ul.sub-menu { width: 100px; margin: 35px 0 0 0; left: 0;}
    .site-header.navsticky .navigation ul.sub-menu li,.navigation ul li:hover > ul.sub-menu li,.navigation ul li.open > ul.sub-menu li { padding: 5px 0 0 10px; }
	.navigation ul li:hover > ul.sub-menu li a,.navigation ul li.open > ul.sub-menu li a { font-size: 14px; line-height: 16px; padding: 0; }


}

@media (max-width:767px) {
    body { font-size: 15px; line-height: 21px; }

    h1 { font-size: 24px; line-height: 26px; }
    h2 { font-size: 20px; line-height: 22px; }
    h3 { font-size: 18px; line-height: 20px; }
    h4 { font-size: 16px; line-height: 18px; }
    h5 { font-size: 14px; line-height: 16px; }


    .container { width: 100%; padding-left: 25px; padding-right: 25px; }
    .row { margin-left: -25px; margin-right: -25px; }
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 { padding-left: 25px; padding-right: 25px; }
    .site-header { padding: 24px 0 14px; background: rgba(255,255,255,1); }
    .logo a img { width: 162px; }
    .header-right  { position: fixed; right: -250px; top: 0; height: 100%; width: 250px; -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; z-index: 19; background: #FFe700; text-align: left; overflow: auto; padding: 30px 0; margin: 0; overflow-x: hidden; }
    .site-header,.site-header.navsticky { left: 0px; padding: 24px 0 14px; -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; }
    .open-menu .site-header,.open-menu .site-header.navsticky { left: -250px; }
    .site-header.navsticky .header-text { display: block; }
    .site-header.navsticky .header-right { padding: 30px 0; }
    .mobile-menu { position: absolute; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); right: 25px; width: 30px; background: url('../media/mobile-menu.jpg'); background-position: 0px 0px; background-repeat: no-repeat; height: 25px; -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s;}
    .mobile-menu img { opacity: 0;  }
    .open-menu .header-right{ right: 0; }
    .site { -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; position: relative; left: 0; }
    .navigation { padding: 0; }
    .navigation ul,.site-header.navsticky .navigation ul { text-align: left; }
    .navigation ul li,.site-header.navsticky .navigation ul li { padding: 0 10px; display: block; }
    .navigation ul li a,.site-header.navsticky .navigation ul li a { padding: 5px 0; position: relative; }
    .header-text { text-align: center; float: none !important; margin-top: 15px; }
    .header-text .mail{ margin-top: 10px; }
    .open-menu .site { left: -250px; }
    .open-menu .mobile-menu { background-position: -30px 0; right: 275px; }
    .thumbail-slider { position: relative; bottom: 0; left: 0; -webkit-transform: translateX(0); transform: translateX(0); margin-top: -30px; }
    .thumbail-slider .slick-initialized .slick-slide { float: none; width: 100% !important; max-width: 320px; margin: 0 auto; }
    .slider-nav .col-sm-4 a { background-image: none !important; padding: 17px 5px; margin-bottom: 10px; border-radius: 50px; background: #FFe700; }
    .slider-nav .col-sm-4 a:before { content: none; }
    .slider-nav img { display: none; }
    .slide-content { position: relative; top: 0; left: 0; -webkit-transform: translate(0,0); transform: translate(0,0); text-align: center; padding-top: 0; }
    .slide-content h2, .form-title { font-size: 22px; line-height: 26px; }
    .intro-section { padding: 20px 0 40px; }
    .one-half { width: 100%; padding-left: 0; }
    .one-half.first { padding-right: 0; }
    .intro-section ul li { font-size: 14px; line-height: 19px; padding-bottom: 17px; }
    .slide-content h2, .form-title,.gallery-text h2 { font-size: 20px; line-height: 22px; margin-bottom: 7px; }
    .slide-content h2 { margin-bottom: 0; }
    .contact-form { max-width: 100%; }
    .contact-form p { line-height: 16px; }
    .site-footer { padding: 25px 0 40px; overflow-x: hidden; }
    .contact-form div.wpcf7 { padding: 15px 27px 10px 20px; }
    button, html input[type="button"], input[type="reset"], input[type="submit"] { padding: 8px 70px; font-size: 16px; width: auto; margin: 0 auto;}
    .site-footer .col-sm-6.pull-right { text-align: center; }
    .author, .testimonial { font-size: 15px; line-height: 19px; }
    .testimonial { padding-top: 15px; }
    .author { padding: 0 70px;  }
    .footer-info { position: relative; bottom: 0; margin-top: 70px; text-align: center; }
    .more-testimonial { padding: 10px 50px 0; text-align: center; }
    a.btn-testimonial { padding: 8px 45px; font-size: 16px; }
    .call a, .footer-info a.call { font-size: 30px; line-height: 30px; }
    .footer-info a.call,.footer-info a.mail { padding-left: 0; float: none; }
    .footer-info a.mail { display: inline-block; margin-top: 10px; }
    .mail a, .footer-info a.mail { font-size: 20px; line-height:  20px;}
    .copyright { padding-top: 0;  }
    .footer-info:before { top: -30px; left: -30px; right: -30px; width: 200%; }

    .banner-single { min-height: 485px; }
    .banner-title { font-size: 26px; line-height: 30px; margin-bottom: 15px; }
    .banner-content { padding: 0 22px; font-size: 16px; line-height: 20px; }
    .service-intro,.services { padding: 0; }
    .welcome-section { padding: 40px 0 25px; }
    .service-intro p { margin-bottom: 15px; }
    .vertical-align { display: block; padding: 35px 0; }
    .vertical-align .col-sm-6 { float: none !important; width: 100%; }
    .main-serviceimage { margin-bottom: 35px; }
    .service-data h2 { font-size: 20px; line-height: 22px; margin-bottom: 10px; }
    .service-data { font-size: 15px; line-height: 20px; }

    .gallery-text { padding: 10px 0 20px; }
    .gallery-text h2 { margin-bottom: 20px; }
    .image-gallery { padding-bottom: 50px; }
    .interiors .slick-slider button { position: absolute; left: -25px; top: 50%; z-index: 1; -webkit-transform: translateY(-50%); transform: translateY(-50%); -webkit-appearance:none; appearance: none; line-height: 0; border: 0; background: none; font-size: 0; height: 36px; width: 36px; background-repeat: no-repeat; background-position: 0px 0px; padding: 0; }
    .interiors .slick-slider button:focus { outline: 0px none; }
    .interiors .slick-slider button.slick-prev { background-image: url('../media/slider-aerrow-prev.jpg'); }
    .interiors .slick-slider button.slick-next { right: -25px;; left: auto; background-image: url('../media/slider-aerrow-next.jpg');}
    .pagecontent-section { padding: 25px 0; }

    .testimonial:before { top: 5px; left: 10px; width: 60px; height: 43px; }

    .site-header.navsticky .navigation ul li:hover > ul.sub-menu,.site-header.navsticky .navigation ul li.open > ul.sub-menu,.navigation ul li:hover > ul.sub-menu, .navigation ul li.open > ul.sub-menu { width: 100%; margin: 0; position: relative; background: transparent; left: 0; padding: 0; border-top: 0px; }
    .navigation ul li:hover > ul.sub-menu li, .navigation ul li.open > ul.sub-menu li,.site-header.navsticky .navigation ul.sub-menu li, .navigation ul li:hover > ul.sub-menu li, .navigation ul li.open > ul.sub-menu li { padding: 0 0 0 10px; }
    .navigation ul li:hover > ul.sub-menu li a, .navigation ul li.open > ul.sub-menu li a,.site-header.navsticky .navigation ul li:hover > ul.sub-menu li a, .site-header.navsticky .navigation ul li.open > ul.sub-menu li a { font-size: 16px; line-height: 20px; padding: 5px 0;}
    .navigation ul li.current-menu-item > a,.navigation ul li.current_page_ancestor > a,.navigation ul li a:hover {border-bottom: 0px; }
    .navigation ul li a:after { content: ""; position: absolute; bottom: -2px; left: 0; right: 0; height: 2px; width: 1000%; left: -30px; background: #FFF; }
    .navigation ul li.current-menu-item > a:after, .navigation ul li.current_page_ancestor > a:after { background: #000; }
	.navigation ul li:hover > ul.sub-menu li:hover > ul.sub-menu, .navigation ul li.open > ul.sub-menu li.open > ul.sub-menu { left: 0; margin-top: 0; }


	.navigation ul li.menu-item-has-children span.plus { display: block; position: absolute; right: 10px; top: 7px; background: url('../media/menu-child.png'); width: 20px; height: 12px; }
	ul.sub-menu li.menu-item-has-children span.plus { right: 0; }
}
