/*---------------------------- THEME CSS -------------------------------*/

*,
*:before,
*:after {  box-sizing: inherit; }
html {font-family: sans-serif;-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%; box-sizing: border-box; position: relative; min-height: 100%; }
body {	margin: 0;  }
ul,ol { margin: 0 0 25px; padding: 0 0 0 25px; }
ul { list-style: disc; }
ol { list-style: decimal; }
li > ul, li > ol { 	margin-bottom: 0; margin-left: 1.5em; }
img { height: auto; max-width: 100%; }
a { color: #FFE700; }
a:hover,a:focus,a:active { text-decoration: none; outline: 0px none; color: #000; text-decoration: none;}
.alignleft { display: inline; float: left; margin-right: 1.5em; }
.alignright { display: inline; float: right; margin-left: 1.5em; }
.aligncenter { clear: both; display: block; margin-left: auto; margin-right: auto; }
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
	content: "";
	display: table;
	table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
	clear: both;
}


body { font-size: 24px; color: #000000;letter-spacing: 0;line-height: 32px; font-family: proxima-nova, sans-serif; font-weight: 400; overflow-x: hidden; }

h1 { font-size: 37px; line-height: 40px; font-weight: 900; }
h2 { font-size: 30px; line-height: 34px; font-weight: 900; }
h3 { font-size: 26px; line-height: 28px; font-weight: 900; }
h4 { font-size: 20px; line-height: 24px; font-weight: 900; }
h5 { font-size: 16px; line-height: 20px; font-weight: 900; }
h6 { font-size: 14px; line-height: 18px; font-weight: 900; }

input::-moz-placeholder,textarea::-moz-placeholder { color: #000 !important; font-size: 20px; line-height: 24px; }
input::-webkit-input-placeholder,textarea::-webkit-input-placeholder { color: #000 !important; font-size: 20px; line-height: 24px; }
input:-ms-input-placeholder,textarea:-ms-input-placeholder { color: #000 !important; font-size: 20px; line-height: 24px; }


/*------------------------- HEADER CSS -------------------------*/

.site-header { background: rgba(255,255,255,0.9); box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20); padding: 25px 0 10px; position: fixed;  z-index: 9; left: 0; right: 0; }
.logo a { display: inline-block; }
.logo a img { width: 260px; }
.header-text { padding-top: 10px; text-align: right; }
.call { margin-bottom: 0; }
.call a { font-size: 37px; color: #000000; line-height: 37px; letter-spacing: 0; font-weight: 900; }
.mail a { font-size: 26px; color: #4A4A4A; letter-spacing: 0; line-height: 26px; }

.site-header.navsticky { background: rgba(255,255,255,1); padding: 15px 0 5px; }
.site-header.navsticky .header-right { padding: 0; }
.site-header.navsticky .header-text { display: none; }
.site-header.navsticky .header-right .navigation  { width: 100%; padding: 0; }
.site-header.navsticky .navigation ul { text-align: right; margin: 0; }
.site-header.navsticky .navigation ul li { padding: 0 24px 10px; }
.site-header.navsticky .navigation ul li a { padding: 0; }

img.logo-sticky  { width: auto; display: none; }
.site-header.navsticky .logo img { display: none; }
.site-header.navsticky img.logo-sticky { display: block; width: auto; }

.navigation { padding-left: 5px; }
.navigation ul { padding: 0; margin: 17px 0 0; list-style: none; text-align: center; }
.navigation ul li { display: inline-block; padding: 5px 18px; position: relative; }
.navigation ul li a { display: inline-block; padding: 6px 0px 0; font-size: 20px; color: #000000; letter-spacing: 0; border-bottom: 2px solid #FFE700; line-height: 28px; }
.navigation ul li a:hover,.navigation ul li.current-menu-item > a,.navigation ul li.current_page_ancestor > a{ border-bottom: 2px solid #000; }
.sub-menu { display: none; }
.navigation ul li:hover > ul.sub-menu { display: block; z-index: 1; position: absolute; top: 0px; left: -20px; width: 180px; text-align: left; margin: 45px 0 0 0; background: #FFF; border-top: 3px solid #ffe700; padding: 0 0 10px 0; }
ul.sub-menu li{ display: block; padding: 5px 0 0px 15px; line-height: 18px; }
.site-header.navsticky .navigation ul.sub-menu {padding: 0 0 5px; }
.site-header.navsticky .navigation ul.sub-menu li { padding:5px 0 5px 15px; }
ul.sub-menu li a { display: inline-block; padding: 5px 0; font-size: 18px; line-height: 18px; border-bottom:0px; text-transform: lowercase; }
.navigation ul.sub-menu li.current-menu-item > a, ul.sub-menu li a:hover { border-bottom:0px none; }
.site-header.navsticky .navigation ul li:hover > ul.sub-menu { margin-top: 35px; }

.navigation ul li:hover > ul.sub-menu li:hover > ul.sub-menu { left:100%; margin-top: -3px; }

.navigation ul li.menu-item-has-children span.plus { display: none; }
/*--------------------- FOOTER CSS ----------------*/
.site-footer { background-size: cover; background-repeat: no-repeat; background-position: center center; padding: 70px 0 85px; position: relative; }
.site-footer:before { content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.88); display: block; }
.contact-form { max-width: 545px; }
.form-title { font-size: 34px; color: #FFE700; letter-spacing: 0; font-weight: 900; margin: 13px 0 15px; }
.contact-form p { font-size: 20px; color: #FFFFFF; letter-spacing: 0; line-height: 23px; }
.contact-form p a { color: #FFE700; font-size: 26px; font-weight: 900;}
.contact-form div.wpcf7 { padding: 15px 0; }
.form-control { height: 52px; font-size: 18px; color: #000; line-height: 22px; -webkit-appearance: none; -moz-appearance: none; appearance: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; padding: 12px; }
.form-control:focus { border: 1px solid #ccc; -webkit-box-shadow: none; box-shadow: none; }
textarea.form-control { height: 195px;  resize: none; }
select.form-control { -webkit-appearance: menulist; -moz-appearance: menulist; appearance: menulist; }
.submit-btn { margin-top: 25px; }
html input[type="button"], input[type="reset"], input[type="submit"] { color: #000; font-size: 24px; background: #FFE700; padding: 10px 70px; width: auto; line-height: 24px; border: 1px solid transparent; border-radius: 50px; }
input[type="submit"]:hover { background: transparent; color: #ffe700; border: 2px solid #ffe700; }

.site-footer .col-sm-6.pull-right { padding-left: 35px; }
.testimonial { padding-top: 55px; font-style: italic; padding-bottom: 10px; }
.author,.testimonial { font-size: 24px; color: #FFFFFF; line-height: 30px; }
.testimonial:before { content: ""; position: absolute; top: 20px; left: 13px; width: 115px; height: 83px; background: url('../media/quote.png'); background-size: cover; background-position: center center; background-repeat: no-repeat; }
.testimonial p { position: relative; }
a.btn-testimonial { color: #FFE700; font-size: 24px; border: 2px solid #FFE700; padding: 10px 38px; width: auto; line-height: 24px; border-radius: 50px; background: transparent; margin-top: 10px; }
a.btn-testimonial:hover,a.btn-testimonial:focus { color: #FFF; border-color: #FFF; }
.footer-info { position: absolute; bottom: 20px; padding: 0 35px; }
.footer-info:before { content: ""; position: absolute; top: -35px; left: 0; right: 0; background: #323232; width: 100%; height: 4px; }
.footer-info a.call { float: left; font-size: 37px; color: #FFE700; line-height: 37px; font-weight: 900; }
.footer-info a.mail { float: right; font-size: 26px; color: #FFE700; letter-spacing: 0; line-height: 26px; padding-left: 50px; padding-top: 5px; }
.copyright { clear: both; font-size: 20px; color: #FFFFFF; line-height: 20px; padding-top: 25px; }
span.wpcf7-not-valid-tip { font-size: 16px; line-height: 18px; padding-top: 5px; }
div.wpcf7-validation-errors,div.wpcf7-mail-sent-ng { border: 0px; margin: 10px 0 0 0; text-align: center; color: #F00; font-size: 16px; line-height: 18px; font-weight: normal; }
div.wpcf7-mail-sent-ok { border: 0px; margin: 10px 0 0 0; text-align: center; color: #398f14; font-size: 24px; line-height: 18px; font-weight: normal;  }

/*----------------- BANNER CSS ----------------*/
.banner-section { position: relative; }
.slick-slide { background-size: cover; background-position: center center; background-repeat: no-repeat; }
.slick-slide img { opacity: 0; }
.slider-nav .col-sm-4 a { background-size: cover; background-position: center; background-repeat: no-repeat; display: block; width: 100%; height: 100%; position: relative; }
.slider-nav .col-sm-4 a:before { content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(255,231,0,0.71); }
.slider-nav img { opacity: 0; }
.slick-slide:focus { outline: 0px none; }
.thumbail-slider { position: absolute; bottom: 25px; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%);}

.slide-content { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%,-50%); transform: translate(-50%,-50%); text-align: center; padding-top: 5px; }
.slide-content h2 { font-size: 36px; color: #000;  margin: 0; line-height: 44px; font-weight: 900; }


/*------------------- INTRO CSS ----*/
.intro-section { padding: 85px; text-align: center; font-weight: 500; }
.one-half { float: left; width: 50%; padding-left: 40px; padding-right: 0; }
.one-half.first { padding-left: 0; padding-right: 40px; }
.one-half ul { text-align: left; }
.intro-section ul{ margin: 55px 0 0; padding: 0 0 0 35px; }
.intro-section ul li { font-size: 20px; color: #4A4A4A; letter-spacing: 0; padding-bottom: 20px; line-height: 25px; font-weight: 400; padding-left: 5px; }
.intro-section ul li:last-child { padding-bottom: 0; }


/*---------------------- SERVICE PAGE --------------------*/
.banner-single { background-size: cover; background-position: center center; background-repeat: no-repeat; position: relative; min-height: 568px; }
.banner-single:before { content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.4); z-index: 1; }
.banner-content { position: absolute; top: 50%; left: 50%; text-align: center; max-width: 620px; margin: 0 auto; -webkit-transform: translate(-50%,-50%); transform: translate(-50%,-50%); color: #FFF; z-index: 3; font-size: 24px; color: #FFFFFF; letter-spacing: 0; line-height: 32px; width: 100%; }
.banner-title { font-size: 37px; color: #FFE700; margin-bottom: 15px; font-weight: 500; margin-bottom: 25px; }

.welcome-section { padding: 70px 0 50px; }
.service-intro { padding: 0 110px; }
.service-intro p { margin-bottom: 20px; }

.service-block-section { background: #ffffff; }
.services { padding: 0 110px; }
.service-image { background-size: cover; background-position: center center; background-repeat: no-repeat; }
.service-image img { opacity: 0; }
.service-data { font-size: 24px; color: #000000; letter-spacing: 0; line-height: 32px; }
.service-data h2 { font-size: 34px; color: #4A4A4A; letter-spacing: 0; line-height: 32px; font-weight: 900; margin-top: 0; }
.vertical-align { display: flex; flex-direction: row; padding: 75px 0 90px;  align-items: center; float: left; width: 100%; }
.vertical-align > [class^="col-"] > div,.vertical-align > [class*=" col-"] > div { flex-grow: 1; }
.service-block-section .service-block:nth-child(odd) { background: #f7f7f7; }
.service-block-section .service-block:nth-child(odd) .vertical-align { -webkit-flex-direction: row-reverse; -moz-flex-direction: row-reverse; flex-direction: row-reverse; }
.service-block-section .service-block:nth-child(odd) .vertical-align .main-serviceimage { float: right; }
.main-serviceimage { padding-right: 15px; padding-left: 55px; }
.service-block-section .service-block:nth-child(even) .main-serviceimage { padding-right: 55px; padding-left: 15px; }
.tiled-gallery,.gallery-row{ width:100%;}
/*---------------------- OUR WORK PAGE --------------------*/

.interiors-section { background: #f7f7f7; }
.gallery-text { max-width: 1040px; margin: 0 auto; text-align: center; padding: 55px 0; }
.gallery-text h2 { font-size: 34px; color: #4A4A4A; letter-spacing: 0; line-height: 32px; margin-bottom: 30px; font-weight: 900; }
.image-gallery { float: left; width: 100%; padding-bottom: 100px; }
.gallery-image { position: relative; border-bottom: 3px solid #FFF; }
.gallery-image img { opacity: 0; width: 100%; height: 100%; }
.gallery-image a.gallery { display: block; height: 100%; width: 100%;  background-size: cover; background-position: center center; background-repeat: no-repeat; }
.gallery-image a.gallery:hover:before { content: ""; position: absolute; top: 0px; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.4); z-index: 0; }
.gallery-image a.gallery .overlay{ opacity: 0; position: absolute; top: 50%; left: 0; right: 0; text-align: center; color: #FFF; -webkit-transform: translateY(-50%); transform: translateY(-50%);}
.gallery-image a.gallery:hover .overlay { opacity: 1; }
.full-height { padding: 0; float: left;  }
.height-full { border-left: 3px solid #FFF; }
.quarter-half-width { border-right: 3px solid #FFF; height: 592px; }
.overlay img { width: auto; display: block; text-align: center; margin: 15px auto 0; opacity: 1; }
.col-4 { width: 45%; }
.col-6 { width: 55%; }
.col-3 { width: 30%; }
.col-7 { width: 70%; }
.gallery-image.quarter { height: 394px; }
.gallery-image.quarter-half { height: 585px; }
.gallery-image.height-full { height: 980px; }
.half-height { height: 310px; }
.half-height-newiamge { height: 282px; }
.interiors-slider { display: none; }



.wp-tiles-byline-wrapper { padding: 0 10px	; position: absolute;top: 50%; left: 0; -webkit-transform: translateY(-50%); transform: translateY(-50%);  width: 100%; text-align: center; }

.pagecontent-section { padding: 70px 0; }

.form-group .wpcf7-form-control-wrap { position: initial; }
